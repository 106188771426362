import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Partner(props) {

    let imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <Naslovnica />
            <Showcase naslov="Suradnja" opis="Tvrtka Astramedika otvorena je za suradnju sa partnerskim tvrtkama. Za mogućnosti suradnje kroz zajedničku izadu aplikacija ili promoviranje naših proizvoda izvan Hrvatske, kontaktirajte nas. " pictureFirst={alternateImagePosition()} image="partner.jpg" />

            <Showcase naslov="Zašto postati naš partner" opis="Stjecanje uvjeta za postizanje partnerstva sa tvrtkom Astramedika d.o.o. kao ovlaštenog partnera za održavanje bolničkog informatičkog sustava 'Sustav prijema pacijenata' (SPP) omogućuje vam proširenje portfelja vaših usluga, prednosti i benefite sinergijske prodaje kao i povećanje korisničke baze " pictureFirst={alternateImagePosition()} image="partner2.jpg" />

            <Showcase naslov="Uvjeti za stjecanje partnerskog statusa" opis="imati barem tri djelatnika sa SPP-ovim certifikatima za održavanje produkta te raspolagati središnjim centrom za postavljanje tehničkih pitanja čija je dostupnost 24h, 7 dana tjedno" pictureFirst={alternateImagePosition()} image="certificate.jpg" />

            <Showcase naslov="Pristupanje u partnerski program" opis="Za pristup u partnerski program tvrtka treba potpisati ugovor o povjerljivosti (NDA) i dokazati da zadovoljava tražene uvjete. Ukoliko ste zainteresirani za program, pošaljite nam e-mail na adresu info@astramedika.hr." pictureFirst={alternateImagePosition()} image="sign.jpg" />

          
        </>
    )

}