
export default function Showcase(props) {
    const style = { backgroundImage: `url('/img/${props.image}')` }
    let p = (props.pictureFirst ) ? "0" : "2"



    return (
        <section className="showcase">
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className={`col-lg-6 text-white showcase-img order-lg-${p}`} style={style}></div>
                    <div className={`col-lg-6 showcase-text order-lg-{p}`}>
                        <h2>{props.naslov} {props.tip}</h2>
                        <p className="lead mb-0">{props.opis}</p>
                    </div>
                </div>
            </div>
        </section>

    );
}
