import Naslovnica from "./Naslovnica"



export default function Contact(props) {
    const style = { backgroundImage: `url('/img/contact.jpg')` }

    return (
        <>
            <Naslovnica />
            <section className="showcase">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className={`col-lg-6 text-white showcase-img order-lg-1`} style={style} ></div>
                        <div className={`col-lg-6 showcase-text order-lg-2`}>
                            <h2>Kontakt</h2>
                            <p className="lead mb-0 left"><i className="bi bi-house-door"></i>Horvaćanska c. 21, 10000 Zagreb, </p>
                            <p className="lead mb-0 left"><i className="bi bi-phone-vibrate"></i> +385 1 5620 900</p>
                            <p className="lead mb-0 left"><i className="bi bi-envelope-at"></i> info@astramedika.hr</p>
                           

                        </div>
                    </div>
                    <div className="row g-0">
                        <div className={`col-lg-12 showcase-text`}>
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Horvaćanska c. 21&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )




}