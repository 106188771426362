
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import Navbar from './Navbar';
import Footer from './Footer';
import About from './About';
import Contact from './Contact';
import Reference from './Reference';
import Privatnost from './Privatnost';
import Zaposlenja from './Zaposlenja';
import Podrska from './Podrska';
import Partner from './Partner';


const tvrtka="Astramedika";

function App() {
  return (
    <div className="App">
      <Navbar />



      <Routes>
        <Route path='/About' element={<About />}>  </Route>
        <Route path='/' element={<Home />}>  </Route>
        <Route path='/Reference' element={<Reference />}>  </Route>
        <Route path='/Contact' element={<Contact />}>  </Route>
        <Route path='/privacy' element={<Privatnost />}>  </Route>
        <Route path='/careers' element={<Zaposlenja />}>  </Route>
        <Route path='/support' element={<Podrska />}>  </Route>
        <Route path='/partner' element={<Partner />}>  </Route>
        {/* <Route path='*' element={<Home />}>  </Route> */}

      </Routes>
      <Footer />
    </div>


  );
}

export default App;
