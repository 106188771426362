import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Zaposlenja(props) {

    return (
        <>
            <Naslovnica />
            <Showcase naslov="Zaposlenja" opis="Tvrtka Astramedika zapošljava djelatnike programske i korisničke podrške. Za više informacija obratiti se na kontakte tvrtke." pictureFirst={false} image="network.jpg" />

            <Showcase naslov="Radno okruženje" opis="Djelatnicima nudimo izazovno i motivirajuće radno okruženje s mogućnošću stručnog napredovanja." pictureFirst={true} image="zaposlenja.jpg" />


          
        </>
    )

}