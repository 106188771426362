import Naslovnica from "./Naslovnica"



export default function Reference(props) {
    const style = { backgroundImage: `url('/img/partner.jpg')` }

    return (
        <>
            <Naslovnica />
            <section className="showcase">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className={`col-lg-6 text-white showcase-img order-lg-1`} style={style} ></div>
                        <div className={`col-lg-6 showcase-text order-lg-2`}>
                            <h2>Reference</h2>
                            <p>Klinička bolnica "Sveti Duh", Zagreb
</p><p>Opća bolnica "Dr. Ivo Pedišić", Sisak
</p><p>Specijalna bolnica za zaštitu djece s neurorazvojnim i motoričkim smetnjama, Zagreb
</p><p>Hrvatsko društvo za nefrologiju, dijalizu i transplantaciju Hrvatskog liječničkog zbora
</p><p>Hrvatska udruga bolničkih liječnika - HUBOL
</p><p>Span
</p><p>Atos IT Solutions and Services d.o.o.
</p><p>Siemens d.d.
</p><p>Roche d.o.o. ODG Diagnostics
</p><p>Input d.o.o.
</p><p>Poliklinika Arithera Comfort
</p><p>Hrvatski liječnički zbor, Hrvatsko društvo za nefrologiju, dijalizu i transplantaciju
</p><p>Mediva d.o.o.
</p><p>Allianz osiguranje
</p><p>Uniqa osiguranje
</p><p>Wiener osiguranje
</p><p>Croatia osiguranje
</p><p>Triglav osiguranje</p>
                           

                        </div>
                    </div>
                    <div className="row g-0">
                        <div className={`col-lg-12 showcase-text`}>
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Horvaćanska c. 21&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )




}