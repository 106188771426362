import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function About(props) {

    let imageFirst = true;
    function alternateImagePosition() {
        imageFirst = !imageFirst;
        return imageFirst;
    }

    return (
        <>
            <Naslovnica />
            <Showcase naslov="O nama" opis="Tvrtka Astramedika osnovana je 2008. godine s ciljem doprinosa zdravstvenoj informatici. Naša programska rješenja odlikuju se inovativnošću i jednostavnošću koja doprinosi kvaliteti rada zdravstvenih ustanova. Naši proizvodi imaju za cilj zadovoljiti sve potrebe radnog procesa zdravstvenih ustanova i njihovih djelatnika. Dugogodišnjim iskustvom u području zdravstva uz korištenje vlastitog istraživanja i razvoja, u mogućnosti smo Vam ponuditi mobilna i stacionarna programska rješenja koja su Vam neophodna u današnjem poslovanju. " pictureFirst={alternateImagePosition()} image="company.jpg" />

            <Showcase naslov="Povezivost s drugim IT sustavima" opis="Uz ponudu napredne tehnologije, olakšavamo Vam povezivanje sa već postojećim zdravstvenim i drugim IT sustavima." pictureFirst={alternateImagePosition()} image="connectivity.jpg" />

            <Showcase naslov="24-satna podrška" opis="24-satna specijalizirana podrška korisnicima osigurava visoku razinu usluge." pictureFirst={alternateImagePosition()} image="support.jpg" />


          
        </>
    )

}