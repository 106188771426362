import Naslovnica from "./Naslovnica"
import Showcase from "./Showcase"

export default function Podrska(props) {

    return (
        <>
            <Naslovnica />
            <Showcase naslov="Podrška" opis="Tvrtka Astramedika osigurava 24h-tnu &quot;2nd-level&quot; podršku svojim ključnim korisnicima." pictureFirst={false} image="support.jpg" />

            <Showcase naslov="Broj korisničke podrške" opis="Trenutni broj krisničke podške je: +385 91 1620904 tijekom radnog vremena, a +385 91 1620902 izvan radnog vremena. Brojevi su namijenjeni pozivima isključivo IT službama naših klijenata." pictureFirst={true} image="database.webp" />


          
        </>
    )

}