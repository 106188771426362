export default function Naslovnica(){
    const style = { backgroundImage: `url('/img/bg.jpg')` }

    return (
        <header className="masthead" style={style}>
        <div className="container position-relative px-5">
            <div className="row justify-content-center">
                <div className="col-xl-6">
                    <div className="text-center text-white text-shadow">
                       
                        <h1 className="mb-5">Astramedika</h1>
                        <h2 > za zdravstvenu informatiku</h2>
                           
                           <span>doprinos zdravstvenoj informatici i njenoj primjeni u liječenju bolesnika.</span>
                    </div>
                </div>
            </div>
        </div>
    </header>

    )
}